// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-project-1-js": () => import("./../../../src/pages/projects/project1.js" /* webpackChunkName: "component---src-pages-projects-project-1-js" */),
  "component---src-pages-projects-project-2-js": () => import("./../../../src/pages/projects/project2.js" /* webpackChunkName: "component---src-pages-projects-project-2-js" */),
  "component---src-pages-projects-project-3-js": () => import("./../../../src/pages/projects/project3.js" /* webpackChunkName: "component---src-pages-projects-project-3-js" */),
  "component---src-pages-projects-project-4-js": () => import("./../../../src/pages/projects/project4.js" /* webpackChunkName: "component---src-pages-projects-project-4-js" */),
  "component---src-pages-projects-project-5-js": () => import("./../../../src/pages/projects/project5.js" /* webpackChunkName: "component---src-pages-projects-project-5-js" */),
  "component---src-pages-projects-project-6-js": () => import("./../../../src/pages/projects/project6.js" /* webpackChunkName: "component---src-pages-projects-project-6-js" */),
  "component---src-pages-projects-project-7-js": () => import("./../../../src/pages/projects/project7.js" /* webpackChunkName: "component---src-pages-projects-project-7-js" */),
  "component---src-pages-projects-project-8-js": () => import("./../../../src/pages/projects/project8.js" /* webpackChunkName: "component---src-pages-projects-project-8-js" */)
}

